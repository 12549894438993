import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from '@contentful/rich-text-types'

const options = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => (
    <Link to={`/project/${data.target.slug}`}>{children}</Link>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      return <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>{children}</a>
    },
  },
  renderText:
    text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
  },
}

const Presslist = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulPress(sort: {fields: date, order: DESC}) {
        edges {
          node {
            date(formatString: "MMMM YYYY")
            contentful_id
            text {
              raw
            }
          }
        }
      }
    }
    `)
    return (
        <ul>
        {data.allContentfulPress.edges.map(({node}) => (
            <li key={node.contentful_id}>{node.date} {renderRichText(node.text, options)}</li>
        ))}
        </ul>
    )
}

export default Presslist